<template>
  <div id="footer">
    <div>
      <span class="me-2 fw-bold">RadioloGOx</span>
      <span class="me-3 fw-100">powered by &copy;</span>
      <a href="https://www.linkdicom.com" target="_blank">
        <img class="ml-2" width="60" src="@/assets/images/linkdicom.gif">

      </a>
    </div>
    <div class="mb-5">
      <br>
    </div>
  </div>
</template>

  <style scoped>
#footer {

     /* position:absolute;
     bottom: 0;
     width:100%;
     height:50px;
      */

     /* Height of the footer */
     text-align: center;
  }
  </style>
